import React, { useEffect } from 'react'
import '../../Controle/Controle.css'

const Controle_sp = () => {

    useEffect(()=>{

        document.querySelector("title").textContent = "Total Quality Solution - CONTROLE";
        
        for(var i = 1; i <= 4; i++){
          document.querySelector(".l"+i).style.color = "white";
        }
        document.querySelector(".l4").style.color = "#D0B042";

    })


  return (

    <>
    
    <div className='controle'>

      <div className='controle_shadow'></div>
      
      <div className='controle_section'>  
        <img src='/assets/img/logo_1.png' alt='Total Quality Solution' />
        <div>
          <h2>En mantenimiento y renovación actualmente.</h2>
        </div>
      </div>

      
    </div>


    <div className='controle_category' data-aos="zoom-in-left">

        <img src='/assets/img/category/image1.png' alt='category' className='shadow-xl'/>
        <img src='/assets/img/category/image2.png' alt='category' className='shadow-xl' />
        <img src='/assets/img/category/image3.png' alt='category' className='shadow-xl' />
        <img src='/assets/img/category/image4.png' alt='category' className='shadow-xl' />
        <img src='/assets/img/category/image5.png' alt='category' className='shadow-xl' />

    </div>



    </>

  )
}

export default Controle_sp