import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (

    <footer className='Footer'>

        <img src='/assets/img/logo_2.png' alt='Total Quality Solution' className='footer_logo' />

        <div className='footer_menu'>
        
        <Link to={''}><h3 className='footer_home'>Accueil</h3></Link>

        <Link to={'news'}><h3 className='footer_news'>nouvelles</h3></Link>

        <Link to={'propos'}><h3 className='footer_about'>À propos</h3></Link>

        <Link to={'team'}><h3 className='footer_team'>Nos équipes</h3></Link>

        <Link to={'conditions'}> <h3 className='footer_conditions'>Conditions D'utilisation</h3></Link>

        </div>

        <div className='footer_social_media'>

            <img src='/assets/img/social_media/fb.svg' alt='tqsmorocco' />
            <img src='/assets/img/social_media/tw.svg' alt='tqsmorocco' />
            <img src='/assets/img/social_media/insta.svg' alt='tqsmorocco' />

        </div>

        <div className='footer_copyright'>

            <p>Copyright @2024 - Design by Saed</p>

        </div>

    </footer>

  )
}

export default Footer