import React from 'react'
import './Header.css'
import { Link } from 'react-router-dom'

const Header = () => {


  let menu = false;
  const openMenu = () => {
    const menuElement = document.querySelector("#menu");
    const barsIcon = document.querySelector(".bars");

    if (!menu) {
      menuElement.style.display = "block"; // Show menu immediately
      menuElement.classList.remove("hide_menu");
      menuElement.classList.add("show_menu");
      barsIcon.src = "/assets/img/close.svg";
      document.querySelector("body").style.overflow = "hidden";
      menu = true;
    } else {
      document.querySelector("body").style.overflow = "auto";
      menuElement.classList.remove("show_menu");
      menuElement.classList.add("hide_menu");
      barsIcon.src = "/assets/img/bars.svg";

      // Hide menu after animation finishes (600ms)
      setTimeout(() => {
        menuElement.style.display = "none";
      }, 600);

      menu = false;
    }
  };


  const hide_menu = () =>{

    const menuElement = document.querySelector("#menu");
    const barsIcon = document.querySelector(".bars");
    
    document.querySelector("body").style.overflow = "auto";
      menuElement.classList.remove("show_menu");
      menuElement.classList.add("hide_menu");
      barsIcon.src = "/assets/img/bars.svg";

      // Hide menu after animation finishes (600ms)
      setTimeout(() => {
        menuElement.style.display = "none";
      }, 600);

      menu = false;

  }


  const translate_sp = () =>{
    document.querySelector(".l1").textContent = "Inicio";
    document.querySelector(".l2").textContent = "SERVICIO";
    document.querySelector(".l3").textContent = "Acerca de";
    document.querySelector(".l4").textContent = "CONTROL";


    // mobile translate
    document.querySelector(".m_l1").textContent = "Inicio";
    document.querySelector(".m_l2").textContent = "SERVICIO";
    document.querySelector(".m_l3").textContent = "Acerca de";
    document.querySelector(".m_l4").textContent = "CONTROL";



    // footer data
    document.querySelector(".footer_home").textContent = "Inicio";
    document.querySelector(".footer_news").textContent = "Noticias";
    document.querySelector(".footer_about").textContent = "Acerca de";
    document.querySelector(".footer_team").textContent = "Nuestros equipos";
    document.querySelector(".footer_conditions").textContent = "Términos de uso";

    document.querySelector(".footer_copyright").textContent = "Copyright @2024 - Diseño por Saed";
  }




  const translate_fr = () =>{
    document.querySelector(".l1").textContent = "ACCUEIL";
    document.querySelector(".l2").textContent = "SERVICE";
    document.querySelector(".l3").textContent = "À PROPOS";
    document.querySelector(".l4").textContent = "CONTRÔLE";


    // mobile translate
    document.querySelector(".m_l1").textContent = "ACCUEIL";
    document.querySelector(".m_l2").textContent = "SERVICE";
    document.querySelector(".m_l3").textContent = "À PROPOS";
    document.querySelector(".m_l4").textContent = "CONTRÔLE";



    // footer data
    document.querySelector(".footer_home").textContent = "Accueil";
    document.querySelector(".footer_news").textContent = "Nouvelles";
    document.querySelector(".footer_about").textContent = "À propos";
    document.querySelector(".footer_team").textContent = "Nos équipes";
    document.querySelector(".footer_conditions").textContent = "Conditions D'utilisation";

    document.querySelector(".footer_copyright").textContent = "Copyright @2024 - Designed by Saed";
  }



  const translate_us = () =>{
    document.querySelector(".l1").textContent = "Home";
    document.querySelector(".l2").textContent = "SERVICE";
    document.querySelector(".l3").textContent = "About";
    document.querySelector(".l4").textContent = "CONTROLE";


    // mobile translate
    document.querySelector(".m_l1").textContent = "Home";
    document.querySelector(".m_l2").textContent = "SERVICE";
    document.querySelector(".m_l3").textContent = "About";
    document.querySelector(".m_l4").textContent = "CONTROLE";


    // footer data
    document.querySelector(".footer_home").textContent = "Home";
    document.querySelector(".footer_news").textContent = "News";
    document.querySelector(".footer_about").textContent = "About";
    document.querySelector(".footer_team").textContent = "Our Teams";
    document.querySelector(".footer_conditions").textContent = "Terms of Use";

    document.querySelector(".footer_copyright").textContent = "Copyright @2024 - Designed by Saed";
  }



  return (

    <>

    <header className='Header'>

        <Link to={'/'}>
            <img src='/assets/img/logo.svg' alt='Total Quality Solution LOGO' className='logo' />
        </Link>


        <div className='menu'>

            <Link to={''}><h2 className='l1'>ACCUEIL</h2></Link>

            <Link to={'service'}><h2 className='l2'>SERVICE</h2></Link>

            <Link to={'propos'}><h2 className='l3'>À PROPOS</h2></Link>

            <Link to={'controle'}><h2 className='l4'>CONTRÔLE</h2></Link>

        </div>


        <div className='flags'>

            <Link to={'/sp'}><img onClick={() => translate_sp()} src='/assets/img/flags/es.png' alt='Total Quality Solution LOGO' className='spain' /></Link>
            <Link to={'/'}><img onClick={() => translate_fr()} src='/assets/img/flags/fr.png' alt='Total Quality Solution LOGO' className='french' /></Link>
            <Link to={'/us'}><img onClick={() => translate_us()} src='/assets/img/flags/us.png' alt='Total Quality Solution LOGO' className='usa' /></Link>

        </div>

        <img onClick={() => openMenu()} src='/assets/img/bars.svg' alt='' className='bars' />


    </header>


    <div className='menu_mobile' id='menu'>

        <Link to={''}><h2 onClick={() => hide_menu()} className='m_l1'>ACCUEIL</h2></Link>

        <Link to={'service'}><h2 onClick={() => hide_menu()} className='m_l2'>SERVICE</h2></Link>

        <Link to={'propos'}><h2 onClick={() => hide_menu()} className='m_l3'>À PROPOS</h2></Link>

        <Link to={'controle'}><h2 onClick={() => hide_menu()} className='m_l4'>CONTRÔLE</h2></Link>




        <div className='flags'>

            <Link to={'/sp'}><img onClick={() => translate_sp()} src='/assets/img/flags/es.png' alt='Total Quality Solution LOGO' className='spain' /></Link>
            <Link to={'/'}><img onClick={() => translate_fr()} src='/assets/img/flags/fr.png' alt='Total Quality Solution LOGO' className='french' /></Link>
            <Link to={'/us'}><img onClick={() => translate_us()} src='/assets/img/flags/us.png' alt='Total Quality Solution LOGO' className='usa' /></Link>

        </div>


        <Link to={'/'}>
            <img src='/assets/img/logo_2.png' alt='Total Quality Solution LOGO' className='logo' />
        </Link>

        

    </div>
    
    </>

  )
}

export default Header