import { BrowserRouter, Routes, Route } from "react-router-dom";
import Controle from "./Components/Controle/Controle";
// import Home from "./Components/Home/Home";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Controle_us from "./Components/Lang/Us/Controle/Controle_us";
import Controle_sp from "./Components/Lang/Sp/Controle_sp";


function App() {

 
  
  return (
    <div className="App">

      <BrowserRouter>
        
        <Header/>

        <Routes>

            {/* <Route path="/" element={<Home/>} /> */}

            {/* fr default langue */}

            <Route path="/fr" element={<Controle/>} />
            <Route path="/" element={<Controle/>} />
            <Route path="/controle" element={<Controle/>} />
            <Route path="/service" element={<Controle/>} />
            <Route path="/propos" element={<Controle/>} />




            {/* espagnol langue */}
            <Route path="/sp" element={<Controle_sp/>} />






            {/* english langue */}
            <Route path="/us" element={<Controle_us/>} />





        </Routes>

        <Footer/>
        
      </BrowserRouter>  


    </div>
  );
}

export default App;
